<template>
  <div class="auth pa-3">
    <form @submit.prevent="Register" v-if="!registerSuccess">
      <v-card-text>
        <v-row align="center" class="mx-0">
          <v-col cols="12">
            <span class="mb-3">Your email address *</span>
            <v-text-field
              solo
              hide-details="auto"
              :rules="[() => !!form.email || 'This field is required']"
              v-model="form.email"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <span class="mb-3">Password *</span>
            <v-text-field
              solo
              hide-details="auto"
              :rules="[() => !!form.password || 'This field is required']"
              v-model="form.password"
              type="password"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              type="submit"
              outlined
              class="px-10"
              :disabled="submitForm"
            >
              Buat Akun
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-banner
        v-model="notifBanner"
        transition="slide-y-transition"
        color="error"
        dark
        v-if="errorMessages"
      >
        {{ errorMessages }}
        <template v-slot:actions="{ dismiss }">
          <v-btn text @click="dismiss"> Dismiss </v-btn>
        </template>
      </v-banner>
    </form>

    <v-card color="primary" dark v-else>
      <div class="text-uppercase text-h5 text-center px-5 pt-5">
        <v-icon size="130">mdi-checkbox-marked-circle-outline</v-icon>
        <div class="mt-3">SUCCESS</div>
      </div>
      <v-card-text>
        <div class="text-center text-button">
          Congratulations, your account has been successfully created.
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined block @click="registerSuccess = false">Okay</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      submitForm: false,
      registerSuccess: false,

      errorMessages: "",
      notifBanner: true,
    };
  },
  methods: {
    Register() {
      this.submitForm = true;

      return new Promise(() => {
        axios
          .post("customer/register", {
            email: this.form.email,
            password: this.form.password,
          })
          .then((response) => {
            console.log(response);
            this.submitForm = false;
            this.registerSuccess = true;
          })

          .catch((error) => {
            console.log(error);
            this.notifBanner = true;
            this.errorMessages = error.response.data.message;
            this.submitForm = false;
          });
      });
    },
  },
};
</script>
